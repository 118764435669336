pre {
	//border: 1px solid $gray-1;
	//border-radius: 2px;
	tab-size: 2;
	//width: 111%;
	//margin-left: -11%;
	//padding-left: 9%;
	//padding-right: 2%;
	//background: #29292e;
	code {
		white-space: pre-wrap;
	}
}

%code-font {
	font-family: $code-font-family;
	font-size: $code-font-size;
	//line-height: $code-line-height;
}

%code-in-rounded-box {
	border-radius: 4px;
	padding: 2px 4px;
	color: $code-color;
	background-color: $code-background-color;
}

%code-style-codersblock {
	padding: 2px 4px;
	border: 1px dashed #adb5db;
	background-color: $gray-1;
}

//-code {
//	white-space: pre-wrap;
//	@extend %code-font;
//	@extend %code-in-rounded-box;
//}

code {
	@extend %code-font;
	@extend %code-style-codersblock;
}

// highlight.js
.hljs {
	font-size: $code-fence-font-size;
	font-weight: 300;
	white-space: pre;
	border: 1px solid $gray-3;
	border-radius: 4px;
}
