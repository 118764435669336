$gray-0: #fafafa;
$gray-1: #f9f9f9;
$gray-2: #eee;
$gray-3: #ddd;
$gray-4: #ccc;
$gray-4: #bbb;
$gray-6: #878787;
$gray-7: #767676;
$gray-8: #515151;
$gray-9: #313131;

$white: #fff;
$red: #ac4142;
$orange: #d28445;
$yellow: #f4bf75;
$green: #90a959;
$cyan: #75b5aa;
$blue: #268bd2;
$brown: #8f5536;

//https://www.client9.com/css-system-font-stack-sans-serif-v3
$root-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Droid Sans", "Ubuntu", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol";

// poole compability
$root-font-size: 17px;
$root-line-height: 1.5;

$body-color: $gray-8;
$body-bg: #fff;

$border-color: #e5e5e5;

$large-breakpoint: 38em;
$large-font-size: 20px;
// end poole compability

// hyde-hyde
$small-device-font-size: 16px;
$large-device-font-size: 18px;

// https://www.client9.com/css-system-font-stack-monospace-v2
$code-font-family: "SF-Mono", "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Roboto Mono", "Ubuntu Mono", "Courier New", Courier, monospace;
$code-font-size: .9rem;
$code-fence-font-size: .9rem;
$code-color: #bf616a;
$code-background-color: #f9f2f4;
$code-line-height: 1.4;

$link-color: $blue;
$link-hover-color: $body-color;

$section__title-font-size: 2.15rem; 

$post__subtitle-font-size: 1.5rem;

$meta-font-size: .8rem;
$meta-font-weight: 300;
$meta-color: $gray-6;

$tag-background-color: $gray-2;
$tag-color: #606570;

$tag-font-size: .667rem;

$item__date-color: #9a9a9a;
$item__date-font-size: 1rem;

$item__title-big-font-size: 1.785rem;

$heading-font-weight: 400;
$h1-font-size: 2.15rem;
$h1-line-height: 1.25;
$h2-font-size: 1.85rem;
$h3-font-size: 1.5rem;
$h4-font-size:1.3rem;
$h5-font-size:1rem;

$sidebar-color: #300030;

$site__title-font-size: 3rem;

$copyright-font-size: 0.7rem;

// post navigation
$post--navigation-color: #c2255c;

// portfolio
$project__title-font-size: $h2-font-size;
$project__subtitle-font-size-big: $h3-font-size;
$project__subtitle-font-size-small: $h4-font-size;
$project__subtitle-font-style: italic;
$project__subtitle-color: #778492;
$ribbon-color: #276582;
$ribbon-background-color: #479fc8;
